import getCookie from "../../csrftoken.js";
import {LitElement, html, css} from 'lit';

export class ProjectCollectionButtonElement extends LitElement {
    static get styles() {
        var returnCss =  [css`
        :host {
            display: block;
            width: 25px;
            height: 25px;
        }
        .entry__button__icon {
            display: block;
            background-color: var(--panel-bg-color,#141414);
            padding: 0.3em;
            border-radius: var(--border-radius-base, 6px);
            box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.2);
            width: 22px;
            height: 25px;
        }
        .entry__button__icon {
            color: rgb(249, 24, 54) !important;
        }
        
        .entry__button__icon:hover {
            cursor: pointer;
            color: #fff !important;
        }
        
        .entry__button--selected .entry__button__icon {
            color: #fff !important;
        }`,

        css`
        .collection-list {
            box-shadow: 0px 0px 10px 2px rgba(0,0,0,1);
            border:none;
            border-radius: var(--border-radius-base, 6px);
            background-color: var(--panel-bg-color, #141414);
            min-width: 300px;
            max-height: 200px;
            padding: 0;
            margin: 0;
            list-style: none;
            padding: 0.75em;
            overflow-y: scroll;
        }
          
        .collection-list__item {
            margin: 0;
            padding: 0;
            padding-top: 0.5em;
            padding-bottom: 0.5em;
            border-bottom: 1px solid #000;
            font-size: 0.80em;
            cursor: pointer;
        }
        
        .collection-item__text {
            color: var(--black);
            font-size: 0.80em;
        }
        
        .collection-item__checkbox {
            margin-right: 0.5em;
        }
        
        .collection-button {
            position: absolute;
            left: 1.20em;
            top: 1.5em;
            font-size: 1.4em;
        }

        input, select, button, textarea{
            border: 1px solid #000;
            background-color: var(--input-bg-color, #222222);
            color: var(--input-color, #FFF);
            padding: 8px 12px;
        }
        `];

        return returnCss;
    }

    listItemTemplates = [];

    static get properties() {
        return {
            project_id: {type: String},
            _list_expanded: {
                type: Boolean,
                state: true
            },
            _listData: {type: Array}
        };
    }

    constructor() {
        super();
        this.project_id = '';
        this._list_expanded = false;
        this._listData = [];
    }

    _handleClick = (e) => {
        this._list_expanded = !this._list_expanded;
        if (this._list_expanded && this._listData.length == 0) {
            fetch('/user/user_lists/get/').then(
                responseData => responseData.json()
            ).then(
                responseData => this._processFetchedItems(responseData)
            );
        }
    }

    _processFetchedItems = (responseData) => {
        this._list_item_array = [];
        if (responseData.length > 0) {
            this._listData = responseData;
        }
    }

    _handleListItemClick = (e) => {
        let listItem = e.target;
        if (!listItem.classList.contains('collection-list__item')) {
            listItem = listItem.parentElement;
        }

        let listItemInput = listItem.getElementsByClassName('collection-item__checkbox')[0];
        this._saveList(listItemInput.value).then(
            responseData => {
                this._list_expanded = false;
                this._listData = [];
            }
        );
        if (e.explicitOriginalTarget != listItemInput) {
            listItemInput.checked = !listItemInput.checked;
        }

    }
    
    _saveList = (listId) => {
        return new Promise((resolve, reject) => {
            let url = '/user/user_lists/save/' + listId + '/' + this.project_id + '/';
            let xhr = new XMLHttpRequest();
            let csrftoken = getCookie('csrftoken');
            xhr.open("POST", url, true);
            xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            xhr.setRequestHeader("X-CSRFToken", csrftoken);
            xhr.onreadystatechange = function() { // Call a function when the state changes.
                if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                    console.log('Update complete');
                    resolve();
                }
            }
            xhr.send();
        });
    }

    _addNewListAction = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            if (e.target.value != '') {
                this._addNewList(e.target.value).then(
                    responseData => {
                        this._listData = [];
                        this._list_expanded = false;
                    }
                );
            }
        }
    }

    _addNewList = (listName) => {
        return new Promise((resolve, reject) => {
            let url = '/list/create/' + this.project_id + '/';
            let xhr = new XMLHttpRequest();
            let csrftoken = getCookie('csrftoken');
            let params = "listName=" + listName;
            xhr.open("POST", url, false);
            xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            xhr.setRequestHeader("X-CSRFToken", csrftoken);
            xhr.onreadystatechange = function() { // Call a function when the state changes.
                if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                    console.log('Update complete');
                    resolve();
                }
            }
            xhr.send(params);
        });
    }
    

    getListItemSubTemplate(listItemData, project_id) {
        var checkedItem = false;
        if (listItemData.fields.projects.length > 0) {
            for (var i = 0; i < listItemData.fields.projects.length; i++) {
                if (listItemData.fields.projects[i] == project_id) {
                    checkedItem = true;
                }
            }
        }

        if (checkedItem) {      
            return html`
                <li class="collection-list__item" @click="${this._handleListItemClick}">
                    <input type="checkbox" class="collection-item__checkbox" name="collectionId" value="${listItemData.pk}" checked="checked">
                    <span class="collection-item__text">${listItemData.fields.name ?? html``}</span>
                </li>
            `;
        } else {
            return html`
                <li class="collection-list__item" @click="${this._handleListItemClick}">
                    <input type="checkbox" class="collection-item__checkbox" name="collectionId" value="${listItemData.pk}">
                    <span class="collection-item__text">${listItemData.fields.name ?? html``}</span>
                </li>
            `;
        }
    }

    getListContents() {
        if (this._list_expanded) {
            return html`
                <ul class="collection-list">
                    ${this._listData.map(
                        (listItemData) => {
                            return this.getListItemSubTemplate(listItemData, this.project_id)
                        }
                    )}
                    <li class="collection-list__add-element"><input class="collection-list__add-element__input" type="text" placeholder="Add new collection" @keyup="${this._addNewListAction}"></li>
                </ul>`;
        }
        return html``;
    }

    getHeartTemplate() {
        return html`
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="heart" class="svg-inline--fa fa-heart fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor" d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path>
            </svg>
        `;
    }

    render() {
        return html`
            <span class="entry__button__icon" @click="${this._handleClick}">${this.getHeartTemplate()}</span>
            ${this.getListContents()}`;
    }
}


window.customElements.define('project-collection-button-element', ProjectCollectionButtonElement);