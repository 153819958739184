import {LitElement, html, css} from 'lit';

/**
 * Intersection observer to animate and make the entries viewable
 */
 let projectInterSectionObserver = new IntersectionObserver(function(entries) {
    entries.forEach(function(entry) {
      if(entry.isIntersecting === true){
        entry.target.classList.add("entry--open")
        projectInterSectionObserver.unobserve(entry.target);
      }
    });
  }, { threshold: [0] });

export class ProjectListElement extends LitElement {
    static get styles() {
        return css`
            :host {
                min-height: 800px;
                user-select: none;
                margin-top: 0.5em;
            }
            .project-list {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(min(230px, 100%), 1fr));
                grid-template-rows: repeat(auto-fill, minmax(200px, 1fr));
                gap: 1rem;
                margin-bottom: 0.5em;
            }

            /* empty state */
            .empty-state {
                display: block;
                width: 100%;
                text-align: center;
                font-size: 1.4em;
                margin-top: 1em;
                margin-bottom: 1em;
            }

            /* loading state */
            .loading-state {
                display: flex;
                width: 100%;
                text-align: center;
                font-size: 1.4em;
                font-weight: bold;
                margin-top: 3em;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: center;
            }

            
            @keyframes animation {
                0% {
                stroke-dasharray: 1 98;
                stroke-dashoffset: -105;
                }
                50% {
                stroke-dasharray: 80 10;
                stroke-dashoffset: -160;
                }
                100% {
                stroke-dasharray: 1 98;
                stroke-dashoffset: -300;
                }
            }
            
            .loading-state svg {
                width: 25px;
                height: 25px;
                margin-right: 0.5em;
            }
            
            #loading-spinner {
                transform-origin: center;
                animation-name: animation;
                animation-duration: 1.2s;
                animation-timing-function: cubic-bezier;
                animation-iteration-count: infinite;
            }

            .search-row {
                display: flex;
                flex-direction: column;
                gap: 1em;
                margin-bottom: 0.5em;
                width: 100%;
            }
            .search-row--horizontal {
                flex-direction: row;
                align-items: center;
                justify-content: right;
                flex-shrink: 2;
            }
            @media only screen and (min-width: 920px) {
                .search-row {
                    flex-direction: row;
                }
            }

            .search-options {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin: auto;
                flex-wrap: wrap;
                font-size: 1.2em;
                box-sizing: border-box;
                width: 100%;
            }

            .search-options_input {
                flex-grow: 2;
                padding-right: 2em;
            }
            .search-options-selected {
                display: flex;
                flex-grow: 2;
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%;
                min-height: 60px;

                background-color: var(--input-bg-color,#222222);
                border-radius: 0.2em;
                box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
            }
            .search-options-selected p {
                margin-left: 1em;
                margin-right: 1em;
                color: #605E5B;
            }
            
            @media only screen and (min-width: 920px) {
                .search-options-selected p {
                    color: #605E5B;
                }
            }
            a, a:hover, a:visited {
                color: var(--link-color,#A89368);
                text-decoration: none;
              }
              a:hover {
                text-decoration: underline;
                color: var(--link-color-hover,#A89368);
              }
              a:visited {
                color: var(--link-color,#A89368);
              }
        `;
    }

    static get properties() {
        return {
            isLoading: {type: Boolean, state: true},
            projectArray: {type: Array, state: true},
            projectTotal: {type: Number, state: true},
            projectLimit: {type: Number, state: true},
            projectOffset: {type: Number, state: true},
            sortingMode: {type: String, state: true},
            currentPage: {type: Number, state: true},
            totalPages: {type: Number, state: true},

            showAdult: {type: Boolean,
                hasChanged(newVal, oldVal) {
                    return newVal !== oldVal;
                },
                converter: {
                    fromAttribute: (value, type) => {
                        if (value === 'true' || value === 'True' || value == '1' ) {
                            return true;
                        }
                        return false;
                    }
                }
            },
            showFurry: {
                type: Boolean,
                hasChanged(newVal, oldVal) {
                    return newVal !== oldVal;
                },
                converter: {
                    fromAttribute: (value, type) => {
                        if (value === 'true' || value === 'True' || value == '1' ) {
                            return true;
                        }
                        return false;
                    }
                }
            },
            showCategory: {
                type: Boolean,
                hasChanged(newVal, oldVal) {
                    return newVal !== oldVal;
                },
                converter: {
                    fromAttribute: (value, type) => {
                        if (value === 'true' || value === 'True' || value == '1' ) {
                            return true;
                        }
                        return false;
                    }
                }
            },
            showCollectionButton: {type: Boolean,
                hasChanged(newVal, oldVal) {
                  return newVal !== oldVal;
                },
                converter: {
                  fromAttribute: (value, type) => {
                    if (value === 'true' || value === 'True' || value == '1' ) {
                      return true;
                    }
                    return false;
                  }
                }
            },

            adultContent: {type: Number,},
            furryContent: {type: Number},
            category: {type: Number},

            universeTags: {type: Array},
            characterTags: {type: Array},
            softwareTags: {type: Array},
            miscTags: {type: Array},

            universeTagId: {type: Number},
            characterTagId: {type: Number},
            softwareTagId: {type: Number},
            miscTagId: {type: Number},
            
        };
    }

    constructor() {
        super();
        this.isLoading = true;
        this.projectArray = [];
        this.projectTotal = 0;
        this.projectLimit = 24;
        this.projectOffset = 0;
        this.sortingMode = '-last_file_date';
        this.currentPage = 1;
        this.totalPages = 1;

        this.showFurry = false;
        this.showAdult = false;
        this.showCategory = false;
        this.showCollectionButton = false;

        this.adultContent = 0;
        this.furryContent = 0;
        this.category = 0;

        this.searchTerm = '';
        this.universeTags = [];
        this.characterTags = [];
        this.softwareTags = [];
        this.miscTags = [];

        this.universeTagId = 0;
        this.characterTagId = 0;
        this.softwareTagId = 0;
        this.miscTagId = 0;
    }

    getTagElementTemplate(tag) {
        return html`
            <tag-element id="${tag.id}" name="${tag.name}" type="${tag.type}" count="${tag.count}"></tag-element>
        `;
    }

    _updateSearchTerm(e) {
        this.searchTerm = e.detail.searchTerm;
        
        const url = new URL(window.location);
        url.searchParams.set('search_text', this.searchTerm);
        window.history.pushState({}, '', url);

        this._fetchProjectData(this._processProjectData);
        //this.requestUpdate();
    }

    getEmptySearchOptionTemplate() {
        if (this.searchTerm.length == 0 && this.universeTags.length == 0 && this.characterTags.length == 0 && this.softwareTags.length == 0 && this.miscTags.length == 0) {
            return html`
                <p>You are using the new experimental site search. Please let us know what you think by posting in the #bugs-and-features channel on our <a href="https://discord.gg/T2DBVzH">Discord</a>.</p>
            `;
        }
    }

    _toggleSwitchCheck(e) {
        if (e.detail.identifier == 'nsfw') {
            this.adultContent = e.detail.toggleState;
        }
        else if (e.detail.identifier == 'furry') {
            this.furryContent = e.detail.toggleState;
        }
        
        this._fetchProjectData(this._processProjectData);
    }

    _updateCategory(e) {
        this.category = e.detail.category;
        this._fetchProjectData(this._processProjectData);
    }

    renderToggleElements() {
        let returnTemplates = [];

        if (this.showAdult || this.showFurry || this.showCategory) {
            returnTemplates.push(html`
                <div class="search-row search-row--horizontal">
                    ${this.showCategory ? html`
                        <category-select-element title="Category" @categorychanged="${this._updateCategory}"></category-select-element>
                    ` : ''}
                    ${this.showAdult ? html`
                        <toggle-switch-multi-element identifier="nsfw" title="18+" toggleState="${this.adultContent}" @toggleswitched="${this._toggleSwitchCheck}"></toggle-switch-multi-element>
                    `: ''}
                    ${this.showFurry ? html`
                        <toggle-switch-multi-element identifier="furry" title="Furry" toggleState="${this.furryContent}" @toggleswitched="${this._toggleSwitchCheck}"></toggle-switch-multi-element>
                    ` : ''}
                </div>
            `);
        }

        return returnTemplates;
    }

    render() {
        let returnArray = [];

        returnArray.push(
            html`
                <div class="search-row">
                    <div class="search-options-selected" @removetag="${this._removeTag}">
                        ${this.getEmptySearchOptionTemplate()}
                        ${this.universeTags.map(tag => this.getTagElementTemplate(tag))}
                        ${this.characterTags.map(tag => this.getTagElementTemplate(tag))}
                        ${this.softwareTags.map(tag => this.getTagElementTemplate(tag))}
                        ${this.miscTags.map(tag => this.getTagElementTemplate(tag))}
                    </div>

                    ${this.renderToggleElements()}
                </div>

                <div class="search-options">
                    <div class="search-row">
                        <search-input-element searchTerm="${this.searchTerm}" @tagadded="${this._tagAdded}" @searchupdated="${this._updateSearchTerm}"></search-input-element>
                
                        <project-order-element @orderchange="${this._changeOrderBy}" orderBy="${this.sortingMode}"></project-order-element>
                    </div>
                </div>
                <pagination-element page="${this.currentPage}" totalPages="${this.totalPages}" @pagechange=${this._pageChanged}></pagination-element>
            `
        );

        if (this.projectArray && this.projectArray.length > 0) {
            returnArray.push(html`
                <div class="project-list">
                    <slot name="project-items"></slot>
                </div>
            `);
        }

        if (this.projectArray && this.projectArray.length === 0) {
            if (this.isLoading) {
                returnArray.push(html`
                    <loading-element></loading-element>
                `);
            } else {
                returnArray.push(html`
                    <div class="empty-state">
                        <h4>No projects found</h4>
                        <p>Try adjusting your search terms.</p>
                    </div>
                `);
            }
        }

        returnArray.push(html`
            <pagination-element page="${this.currentPage}" totalPages="${this.totalPages}" @pagechange=${this._pageChanged}></pagination-element>
        `);

        return returnArray;
    }

    getProjectTemplate(projectData) {
        return html`
            <project-element class="entry" project_id="${projectData.uuid}" project_name="${projectData.title}" .project_thumb=${projectData.item_thumb} user_id="${projectData.author.user_id}" username="${projectData.author.username}" profilename="${projectData.author.profile_name}" staff="${projectData.author.staff}" patron="${projectData.author.paid_supporter}" verified="${projectData.author.verified_uploader}" nsfw="${projectData.adult_content}" furry="${projectData.furry_content}" showCollectionButton="${this.showCollectionButton}"></project-element>
        `;
    }

    getProjectItems() {
        return html`${this.projectArray.map(
            (projectData) => {
                return this.getProjectTemplate(projectData)
            }
        )}`;
    }

    _tagAdded(e) {
        let tag = e.detail.tag;

        if (tag.type === 'universe') {
            for (let i = 0; i < this.universeTags.length; i++) {
                if (this.universeTags[i].id === tag.id) {
                    return;
                }
            }
            this.universeTags.push(tag);
        }
        if (tag.type === 'character') {
            for (let i = 0; i < this.characterTags.length; i++) {
                if (this.characterTags[i].id === tag.id) {
                    return;
                }
            }
            this.characterTags.push(tag);
        }
        if (tag.type === 'software') {
            for (let i = 0; i < this.softwareTags.length; i++) {
                if (this.softwareTags[i].id === tag.id) {
                    return;
                }
            }
            this.softwareTags.push(tag);
        }
        if (tag.type === 'misc') {
            for (let i = 0; i < this.miscTags.length; i++) {
                if (this.miscTags[i].id === tag.id) {
                    return;
                }
            }
            this.miscTags.push(tag);
        }

        this._fetchProjectData(this._processProjectData);
        //this.requestUpdate();
    }

    _removeTag(e) {
        let tagId = e.target.id;
        let tagType = e.target.type;
        if (tagType === 'universe') {
            for (let i = 0; i < this.universeTags.length; i++) {
                if (this.universeTags[i].id == tagId) {
                    this.universeTags.splice(i, 1);
                }
            }
        }
        if (tagType === 'character') {
            for (let i = 0; i < this.characterTags.length; i++) {
                if (this.characterTags[i].id == tagId) {
                    this.characterTags.splice(i, 1);
                }
            }
        }
        if (tagType === 'software') {
            for (let i = 0; i < this.softwareTags.length; i++) {
                if (this.softwareTags[i].id == tagId) {
                    this.softwareTags.splice(i, 1);
                }
            }
        }
        if (tagType === 'misc') {
            for (let i = 0; i < this.miscTags.length; i++) {
                if (this.miscTags[i].id == tagId) {
                    this.miscTags.splice(i, 1);
                }
            }
        }

        this._fetchProjectData(this._processProjectData);

        //this.requestUpdate();
    }

    _processProjectData = (response) => {
        this.projectTotal = response.count;
        this.projectArray = response.results;
        const event = new CustomEvent('project-list-updated', {
            detail: {
                projectArray:  this.projectArray,
                showCollectionButton: this.showCollectionButton
            }, 
            bubbles: true,
            composed: true
        });
        this.dispatchEvent(event);
        this.totalPages = Math.ceil(this.projectTotal / this.projectLimit);
        this.isLoading = false;
        if (this.totalPages < this.currentPage) {
            this.currentPage = this.totalPages;
            this._fetchProjectData(this._processProjectData);
        }
    }

    _clearProjectsAnimate() {
        return new Promise((resolve) => {
            let projectElements = this.shadowRoot.querySelectorAll('.entry');
            if (projectElements.length > 0) {
                for (let i = 0; i < projectElements.length; i++) {
                    projectElements[i].classList.remove('entry--open');
                }
            }
            resolve();
        });
    }

    _fetchProjectData = (callback) => {
        if (this.projectArray.length > 0) {
            this._clearProjectsAnimate().then(() => {
                this._runFetch(callback);
            });
        } else {
            this._runFetch(callback);
        }

    }

    _fetchTagData = (tagType, tagId, callback) => {
        var tagUrl = '/api/tag/' + tagType + '/item/' + tagId + '/?format=json';

        var myInit = {
            method: 'GET'
        };

        var myRequest = new Request(tagUrl, myInit);

        fetch(myRequest).then(
            responseData => {
                if (responseData.ok) {
                    return responseData.json();
                }
                return null;
            }
        ).then(
            responseData => {
                callback(responseData);
            }
        ).catch(function (e) {
            console.log(e);
        });
    }

    _promiseUniverseTags() {
        return new Promise((resolve) => {
            if (this.universeTagId) {
                this._fetchTagData('property', this.universeTagId, (response) => {
                    if (response) {
                        this.universeTags.push(response);
                    }
                    resolve();
                });
            } else {
                resolve();
            }
        });
    }

    _promiseCharacterTags() {
        return new Promise((resolve) => {
            if (this.characterTagId) {
                this._fetchTagData('character', this.characterTagId, (response) => {
                    if (response) {
                        this.characterTags.push(response);
                    }
                    resolve();
                });
            } else {
                resolve();
            }
        });
    }

    _promiseSoftwareTags() {
        return new Promise((resolve) => {
            if (this.softwareTagId) {
                this._fetchTagData('software', this.softwareTagId, (response) => {
                    if (response) {
                        this.softwareTags.push(response);
                    }
                    resolve();
                });
            } else {
                resolve();
            }
        });
    }
    _promiseMiscTags() {
        return new Promise((resolve) => {
            if (this.miscTagId) {
                this._fetchTagData('misc', this.miscTagId, (response) => {
                    if (response) {
                        this.miscTags.push(response);
                    }
                    resolve();
                });
            } else {
                resolve();
            }
        });
    }

    _promiseFetchProject() {
        return new Promise((resolve) => {
            this._fetchProjectData(this._processProjectData);
            resolve();
        });
    }

    _loadProjects = () => {
        console.log('loading projects');

        const url = new URL(window.location);
        this.universeTagId = url.searchParams.get('universe_tag');
        this.characterTagId = url.searchParams.get('character_tag');
        this.softwareTagId = url.searchParams.get('software_tag');
        this.miscTagId = url.searchParams.get('general_tag');
        this.searchTerm = url.searchParams.get('search_text') ?? '';

        const universePromise = this._promiseUniverseTags();
        const characterPromise = this._promiseCharacterTags();
        const softwarePromise = this._promiseSoftwareTags();
        const miscPromise = this._promiseMiscTags();

        Promise.all([universePromise, characterPromise, softwarePromise, miscPromise]).then(
            this._promiseFetchProject.bind(this)
        );
    }

    // Pagination event handlers
    _pageChanged = (event) => {
        if (event.detail.page !== this.currentPage && event.detail.page >= 1 && event.detail.page <= this.totalPages) {
            this.currentPage = event.detail.page ?? 1;

            const url = new URL(window.location);
            url.searchParams.set('page', this.currentPage);
            window.history.pushState({}, '', url);

            this._fetchProjectData(this._processProjectData);
        }
    }

    _getTagIdParameters() {
        let returnString = '';
        if (this.universeTags.length > 0) {
            for (let i = 0; i < this.universeTags.length; i++) {
                returnString += '&property_tag=' + this.universeTags[i].id;
            }
        }
        if (this.characterTags.length > 0) {
            for (let i = 0; i < this.characterTags.length; i++) {
                returnString += '&character_tag=' + this.characterTags[i].id;
            }
        }
        if (this.softwareTags.length > 0) {
            for (let i = 0; i < this.softwareTags.length; i++) {
                returnString += '&software_tag=' + this.softwareTags[i].id;
            }
        }
        if (this.miscTags.length > 0) {
            for (let i = 0; i < this.miscTags.length; i++) {
                returnString += '&general_tag=' + this.miscTags[i].id;
            }
        }
        return returnString;
    }

    _getContentFlagParameters() {
        let returnString = '';

        if (this.showAdult){
            returnString += '&adult_content=';
            switch (this.adultContent) {
                case 1:
                    returnString += 'unknown';
                    break;
                case 2:
                    returnString += 'true';
                    break;
                case 0:
                    returnString += 'false';
                break;
            }
        }
        if (this.showFurry) {
            returnString += '&furry_content='
            switch (this.furryContent) {
                case 1:
                    returnString += 'unknown';
                    break;
                case 2:
                    returnString += 'true';
                    break;
                case 0:
                    returnString += 'false';
                break;
            }
        }
        
        if (this.showCategory) {
            if (this.category != 0) {
                returnString += '&category=';
                returnString += this.category;
            }
        }

        return returnString;
    }

    _runFetch(callback) {
        this.projectOffset = (this.currentPage - 1) * this.projectLimit;

        var projectUrl = '/api/projects/list/?format=json&limit=' + this.projectLimit + '&offset=' + this.projectOffset + '&order_by=' + this.sortingMode + '&search_text=' + this.searchTerm + this._getTagIdParameters() + this._getContentFlagParameters();

        var myInit = {
            method: 'GET'
        };

        var myRequest = new Request(projectUrl, myInit);

        fetch(myRequest).then(
            responseData => responseData.json()
        ).then(
            responseData => callback(responseData)
        ).catch(function (e) {
            console.log(e);
        });
    }

    _changeOrderBy(event) {
        this.sortingMode = event.detail.orderBy;
        this.currentPage = 1;

        const url = new URL(window.location);
        url.searchParams.set('order_by', this.sortingMode);
        url.searchParams.set('page', this.currentPage);
        window.history.pushState({}, '', url);

        this._fetchProjectData(this._processProjectData);
    }

    connectedCallback() {
        super.connectedCallback();
        window.addEventListener('DOMContentLoaded', this._loadProjects);
        window.addEventListener('popstate', this._loadProjects);
    }
    disconnectedCallback() {
        window.removeEventListener('popstate', this._loadProjects);
        window.removeEventListener('DOMContentLoaded', this._loadProjects);
        super.disconnectedCallback();
    }
}

function getProjectItems(projectArray, showCollectionButton = false) {
    return `${projectArray.map(
        (projectData) => {
            return `<project-element 
                        slot="project-items"
                        class="entry entry--open"
                        project_id="${projectData.uuid}"
                        project_name="${projectData.title}"
                        project_thumb='${JSON.stringify(projectData.item_thumb)}'
                        user_id="${projectData.author.user_id}"
                        username="${projectData.author.username}"
                        profilename="${projectData.author.profile_name}"
                        staff="${projectData.author.staff}"
                        patron="${projectData.author.paid_supporter}"
                        verified="${projectData.author.verified_uploader}"
                        nsfw="${projectData.adult_content}"
                        furry="${projectData.furry_content}"
                        showCollectionButton="${showCollectionButton}">
                    </project-element>`;
        }
    )}`;
}

window.customElements.define('project-list-element', ProjectListElement);
document.addEventListener('project-list-updated', function(e) {
    e.target.innerHTML = getProjectItems(e.detail.projectArray, e.detail.showCollectionButton);
    
    let projectElements = document.querySelectorAll('.entry');
    if (projectElements.length > 0) {
        for (let i = 0; i < projectElements.length; i++) {
            projectInterSectionObserver.observe(projectElements[i]);
        }
    }
});