
function TagSelectElement(element) {
    this.containerElement = element;
    this.selectElement = null;
    this.clearElement = null;
    this.selectionPlaceholderElement = null;

    this.initialize = function() {
        this.selectElement = this.containerElement.getElementsByTagName("select")[0] ?? null;
        if (!this.selectElement) {
            return;
        }

        this.clearElement = this.containerElement.getElementsByClassName("js-tag-select-clear")[0] ?? null;
        if (!this.clearElement) {
            return;
        }
        this.selectionPlaceholderElement = this.clearElement.getElementsByClassName("js-tag-select-placeholder")[0] ?? null;
        if (!this.selectionPlaceholderElement) {
            return;
        }
        this.selectionPlaceholderElement.innerText = this.selectElement.selectedOptions.length ?? '0';

        this.selectElement.addEventListener("change", this.onChange.bind(this));
        this.clearElement.addEventListener("click", this.onClear.bind(this));
    };
    this.onChange = function(event) {
        this.selectionPlaceholderElement.innerText = this.selectElement.selectedOptions.length ?? '0';
    };
    this.onClear = function(event) {
        this.selectElement.selectedIndex = -1;
        this.selectionPlaceholderElement.innerText = this.selectElement.selectedOptions.length ?? '0';
    }
}

document.addEventListener("DOMContentLoaded", function(event) {
    let tagSelectElement = document.getElementsByClassName('js-tag-select');
    for (var i = tagSelectElement.length - 1; i >= 0; i--) {
        let tagSelectComponent = new TagSelectElement(tagSelectElement[i]);
        tagSelectComponent.initialize();
    }
});