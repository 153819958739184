import {LitElement, html, css} from 'lit';

export class SearchTermElement extends LitElement {
    static get styles() {
        return css`
            :host {
                display: flex;
                padding:0;
                margin:0;
            }
            .tag-element {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                background-color: #333;
                margin: 0.4em;
                padding: 0.5em;
                border: 1px solid #444;
                border-radius: 3px;
                font-weight: bold;
                cursor: pointer;
            }
            .tag-element--universe {
                background-color: #1e3416;
            }
            .tag-element--character {
                background-color: #17334a;
            }
            .tag-element--software {
                background-color: #350606;
            }
            .tag-element--misc {
                background-color: #333;
            }
            .tag-element-type {
                margin-right: 0.3em;
            }
            .tag-element-name {
                color: #FFF;
                margin-right: 0.3em;
            }
            .tag-element-count {
                margin-right: 0.3em;
            }
            .tag-element-delete {
                color: #aaa;
            }
            .tag-element:hover .tag-element-delete {
                color: #FFF;
            }
        `;
    }
    
    static get properties() {
        return {
            id: { type: Number },
            name: { type: String },
            type: { type: String },
            count: { type: Number },
        };
    }

    

    constructor() {
        super();
        this.name = '';
    }

    render() {
        if (this.name != '') {
            return html`
                <div class="tag-element">
                    <span class="tag-element-name">${this.name}</span>
                </div>
            `;
        }
    }
}

window.customElements.define('search-term-element', SearchTermElement);