import {LitElement, html, css} from 'lit';

export class PaginationElement extends LitElement {
    static get styles() {
        return css`
            :host {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                margin-bottom: 0.5em;
            }
            .pagination-container {
                display: flex;
                flex-direction: row;
                background-color: var(--panel-bg-color,#141414);
                border-collapse: collapse;
                border-radius: var(--border-radius-base,6px);
                box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.2);
                margin-left: 0.5em;
                margin-right: 0.5em;
            }
            .pagination-container--left {
                margin-left: 0;
            }
            .pagination-container--right {
                margin-right: 0;
            }
            .pagination-item {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                width: 50px;
                height: 50px;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                margin: 0.3em;
                border-radius: 6px;
                transition: 0.2s ease-in-out;
                user-select: none;
                font-weight: bold;
            }
            .pagination-item--selected {
                background-color: var(--button-bg-color,#65563A);
                box-shadow: 0px 2px 5px 0px var(--button-bg-color,#65563A);
                color: var(--button-color, #fff)
            }
            .pagination-item:hover {
                color: var(--primary-color,#00a1ff);
            }
            .pagination-item svg {
                width: 16px;
                height: 26px;
            }
        `;
    }
    
    static get properties() {
        return {
            page: { type: Number },
            totalPages: { type: Number },
        };
    }

    constructor() {
        super();
        this.page = 1;
        this.totalPages = 1;
    }

    pageUpdate() {
        this.dispatchEvent(new CustomEvent('pagechange', {
            bubbles: true,
            composed: true,
            detail: {
                page: this.page
            }
        }));
    }

    onPageClick(page) {
        if (this.page !== page) {
            this.page = page;
        }
        this.pageUpdate();
    }

    onPreviousClick() {
        if (this.page > 1) {
            this.page--;
        }
        this.pageUpdate();
    }

    onNextClick() {
        if (this.page < this.totalPages) {
            this.page++;
        }
        this.pageUpdate();
    }

    renderLeftChevronSvg() {
        return html`
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path fill="currentColor" d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path>
            </svg>
        `;
    }

    renderRightChevronSvg() {
        return html`
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
            </svg>
        `;
    }

    renderPaginationItem(page, pageText = '') {
        return html`
            <div class="pagination-item ${this.page === page ? 'pagination-item--selected' : ''}" @click="${() => this.onPageClick(page)}">
                ${pageText !== '' ? pageText : page}
            </div>
        `;
    }

    renderPaginationNumbers() {
        let paginationItems = [];

        if (this.totalPages > 0) {
            if (this.totalPages >= 10) {
                // Near the start
                if (this.page < 5) {
                    for (let i = 1; i < 6; i++) {
                        paginationItems.push(this.renderPaginationItem(i));
                    }
                    paginationItems.push(this.renderPaginationItem(6, '...'));
                    paginationItems.push(this.renderPaginationItem(this.totalPages));
                }
                // Near the end
                if (this.page > this.totalPages - 5) {
                    paginationItems.push(this.renderPaginationItem(1));
                    paginationItems.push(this.renderPaginationItem(2, '...'));
                    for (let i = this.totalPages - 4; i < this.totalPages + 1; i++) {
                        paginationItems.push(this.renderPaginationItem(i));
                    }
                }
                // Somewhere in the middle
                if (this.page > 4 && this.page < this.totalPages - 4) {
                    paginationItems.push(this.renderPaginationItem(1));
                    paginationItems.push(this.renderPaginationItem(2, '...'));
                    for (let i = this.page - 2; i < this.page + 3; i++) {
                        paginationItems.push(this.renderPaginationItem(i));
                    }
                    paginationItems.push(this.renderPaginationItem(this.totalPages - 1, '...'));
                    paginationItems.push(this.renderPaginationItem(this.totalPages));
                }
            } else {
                for (let i = 1; i <= this.totalPages; i++) {
                    paginationItems.push(this.renderPaginationItem(i));
                }
            }
                
        }
        return paginationItems;
    }

    render() {
        return html`
            <div class="pagination-container pagination-container--left">
                <div class="pagination-item" @click="${() => this.onPreviousClick()}">${this.renderLeftChevronSvg()}</div>
            </div>

            <div class="pagination-container">
                ${this.renderPaginationNumbers()}
            </div>

            <div class="pagination-container pagination-container--right">
                <div class="pagination-item" @click="${() => this.onNextClick()}">${this.renderRightChevronSvg()}</div>
            </div>
        `;
    }
}

window.customElements.define('pagination-element', PaginationElement);