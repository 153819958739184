import {LitElement, html, css} from 'lit';

export class ToggleSwitchMultiElement extends LitElement {
    static get styles() {
        return css`
            :host {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
            .toggle-switch-element-title {
                font-size: 1.3em;
                margin-right: 0.5em;
                font-weight: bold;
            }

            .toggle-switch-element {
                display: flex;
                width: 75px;
                height: 30px;
                background-color: var(--input-bg-color-lighter,#222222);
                color: var(--input-color-lighter,#A89368);
                border-radius: 50px;
                position: relative;
                box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.4);
            }

            .toggle-switch-element-inner {
                display: flex;
                width: 24px;
                height: 24px;
                border-radius: 50px;
                background-color: var(--moderation-icon-red, #FF0000);
                position: absolute;
                top: 3px;
                left: 3px;
                transition: all 0.2s ease-in-out;
                box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
                justify-content: center;
                align-items: center;
            }

            .toggle-switch-element-inner--first {
                background-color: var(--moderation-icon-green, #00FF00);
                left: calc(70% - 28px);
            }

            .toggle-switch-element-inner--second {
                background-color: var(--moderation-icon-green, #00FF00);
                box-shadow: 0px 0px 5px 0px #0F0;
                left: calc(100% - 28px);
            }

            .toggle-switch-element-svg {
                color: color: #fff;
                width: 16px;
                height: 16px;
            }
        `;
    }
    
    static get properties() {
        return {
            maxOptions: {type: Number},
            toggleState: {type: Number},
            identifier: {type: String},
            title: {type: String}
        };
    }

    constructor() {
        super();
        this.maxOptions = 2;
        this.toggleState = 0;
        this.identifier = "";
        this.title = "";
    }

    _clicked(e) {
        this.toggleState = this.maxOptions !== this.toggleState ? this.toggleState + 1 : 0;
        this.dispatchEvent(new CustomEvent('toggleswitched', {
            detail: {
                identifier: this.identifier,
                toggleState: this.toggleState
            }
        }));
    }

    _getIcon() {
        if (this.toggleState == 1) {
            return html`
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" class="toggle-switch-element-svg" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                </svg>
            `;
        }
        if (this.toggleState == 2) {
            return html`        
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle" class="toggle-switch-element-svg" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <circle fill="currentColor" cx="50"cy="50" r="50"></circle>
                </svg>
            `;
        }
        return html`
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" class="toggle-switch-element-svg" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
            <path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>
        </svg>
        `;
    }

    render() {
        return html`
            <div class="toggle-switch-element-title" @click="${this._clicked}">
                ${this.title}:
            </div>
            <div class="toggle-switch-element" @click="${this._clicked}">
                <div class="toggle-switch-element-inner ${this.toggleState === 1 ? 'toggle-switch-element-inner--first' : ''} ${this.toggleState === this.maxOptions ? 'toggle-switch-element-inner--second' : ''}">
                    ${this._getIcon()}
                </div>
            </div>
        `;
    }
}

window.customElements.define('toggle-switch-multi-element', ToggleSwitchMultiElement);