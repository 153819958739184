import jquery from 'jquery';
import {LitElement, html, css} from 'lit';

export class SearchInputElement extends LitElement {
    static get styles() {
        return css`
            :host {
                display: flex;
                flex-grow: 2;
                flex-direction: column;
                position: relative;
            }
            .search-container {
                display: flex;
                flex-direction: row;
                flex-grow: 2;
                align-items: center;
                background-color: var(--input-bg-color-lighter,#222222);
                color: var(--input-color-lighter,#A89368);
                border: 1px solid var(--button-bg-color-hover,#4E432E);
                border-radius: 0.2em;
                font-size: 1.2em;
                padding: 8px 12px;
            }
            .search-container--selected {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
            input {
                background-color: var(--input-bg-color-lighter,#222222);
                color: var(--input-color-lighter,#A89368);
                height: 100%;
                width: 100%;
                border: 0;
                font-size: 1.2em;
            }

            .search-type-selected {
                display: flex;
                align-items: center;
                margin-right: 0.3em;
                padding: 0.2em;
                padding-left: 0.5em;
                padding-right: 0.5em;
                border-radius: var(--border-radius-base);
                border: 1px solid #444;
                color: #cbcbcb;
            }

            .search-type-selected--universe {
                background-color: #1e3416;
            }
            .search-type-selected--character {
                background-color: #17334a;
            }
            .search-type-selected--software {
                background-color: #350606;
            }
            .search-type-selected--misc {
                background-color: #333;
            }

            .search-type-suggestions {
                display: none;
                position: absolute;
                flex-direction: column;
                top: 52px;
                z-index: 2;
                width: 100%;
                opacity: 0;
                transition: 0.5s ease-in-out;
                box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.2);
            }
            
            .search-type-suggestions.search-type-suggestions--focused {
                display: flex;
                opacity: 1;
            }

            .search-type-suggestions.search-type-suggestions--focused.search-type-suggestions--selected {
                display: none;
            }

            .search-type-suggestions .search-type-suggestion:last-child {
                border-bottom-right-radius: 6px;
                border-bottom-left-radius: 6px;
            }

            .search-type-suggestion {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0.6em;
                background-color: var(--input-bg-color,#222222);
                color: var(--input-color-lighter,#A89368);
                border-bottom: 1px solid var(--button-bg-color-hover,#4E432E);
                cursor: pointer;
                justify-content: space-between;
            }
            .search-type-suggestion:hover, .search-type-suggestion--selected {
                background-color: var(--input-bg-color-lighter,#222222);
            }

            .search-type-suggestion-text {
                display: block;
            }
            .search-type-suggestion .search-type-suggestion-muted {
                display: block;
                color: #ACACAC;
                margin-left: 0.5em;
            }
            .search-type-suggestion-muted pre {
                display: inline;
                margin: 0;
                padding: 0;
                font-weight: bold;
                border: 1px solid darkgray;
                padding: 0.2em 0.3em;
                padding-bottom: 0.1em;
                border-radius: 2px;
                background-color: #CCC;
                color: #000;
            }
        `;
    }
    
    static get properties() {
        return {
            searchTerm: { type: String },
            searchType: { type: Number },
            searchTypeSelected: { type: Boolean },
            hasFocus: { type: Boolean, state: true },
            backspaceBufferTriggered: { type: Boolean, state: true },
            searchTypeSuggestionTagArray: { type: Array },
            searchTimeout: { type: Number, state: true },
            onBlurTimeout: { type: Number, state: true },
        };
    }

    constructor() {
        super();
        this.searchTerm = '';
        this.searchType = 0;
        this.searchTypeSelected = false;
        this.hasFocus = false;
        this.backspaceBufferTriggered = false;
        this.searchTypeSuggestionTagArray = [];
        this.searchTagSelectionIndex = -1;
        this.searchTimeout = -1;
        this.onBlurTimeout = -1;
    }

    _inputBuffer(e) {
        if (e.keyCode == 13 && this.searchType != 0 && !this.searchTypeSelected) {
            this._onInputChange(e);
        }

        let keyCodes = [8,13,40,38,27];
        if (!keyCodes.includes(e.keyCode) && this.searchType == 0) {
            if (this.searchTimeout > 0) {
                clearTimeout(this.searchTimeout);
            }
            this.searchTimeout = setTimeout(this._onInputChange.bind(this), 1000, e);
            this.requestUpdate();
            return;
        }
        this._onInputChange(e);
    }

    _onInputChange(e) {
        let inputElement = this.shadowRoot.querySelector('#search-input');
        this.searchTerm = inputElement.value;
        if (e.keyCode) {
            if (e.keyCode == 27) {
                inputElement.blur();
            }

            // Specific tag search mode
            if (this.searchTypeSelected) {
                // Backspace
                if (e.keyCode === 8 && this.searchTerm == '') {
                    if (this.backspaceBufferTriggered) {
                        this._resetSearch();
                    } else {
                        this.backspaceBufferTriggered = true;
                    }
                    return;
                }

                // Down
                if (e.keyCode == 40) {
                    if (this.searchTagSelectionIndex < this.searchTypeSuggestionTagArray.length - 1) {
                        this.searchTagSelectionIndex++;
                        this.requestUpdate();
                    }
                    return;
                }
                // Up
                if (e.keyCode == 38) {
                    if (this.searchTagSelectionIndex > -1 && this.searchTypeSuggestionTagArray.length > 0) {
                        this.searchTagSelectionIndex--;
                        this.requestUpdate();
                    }
                    return;
                }
                // Enter
                if (e.keyCode == 13 && this.searchTagSelectionIndex > -1) {
                    this._confirmSuggestion();
                }

                // Any other key change in the search term
                if (this.searchTerm != '' && e.keyCode != 13) {
                    this._fetchTagList();
                }
            // We are selecting a search type
            } else {
                // Down
                if (e.keyCode == 40) {
                    if (this.searchType < 4) {
                        this.searchType = (this.searchType + 1) % 5;
                    }
                    return;
                }
                // Up
                if (e.keyCode == 38) {
                    if (this.searchType > 0) {
                        this.searchType = (this.searchType - 1) % 5;
                    }
                    return;
                }
                // Enter
                if (e.keyCode == 13) {
                    if (this.searchType != 0) {
                        this.searchTypeSelected = true;
                        this._fetchTagList();
                    }
                    return;
                }

                this.dispatchEvent(new CustomEvent('searchupdated', {
                    bubbles: true,
                    composed: true,
                    detail: {
                        searchTerm: this.searchTerm
                    }
                }));
            }
        }
    }

    _fetchTagList() {
        let searchParam = this.searchTerm ? `&search=${this.searchTerm}` : '';
        if (this.searchType == 1) {
            var tagUrl = '/api/tag/property/?format=json' + searchParam;
        }
        if (this.searchType == 2) {
            var tagUrl = '/api/tag/character/?format=json' + searchParam;
        }
        if (this.searchType == 3) {
            var tagUrl = '/api/tag/software/?format=json' + searchParam;
        }
        if (this.searchType == 4) {
            var tagUrl = '/api/tag/misc/?format=json' + searchParam;
        }

        var myInit = {
            method: 'GET'
        };

        var myRequest = new Request(tagUrl, myInit);

        fetch(myRequest).then(
            responseData => responseData.json()
        ).then(
            responseData => {
                if (responseData.count > 0 && responseData.results.length > 0) {
                    this.searchTypeSuggestionTagArray = responseData.results;
                    this.searchTagSelectionIndex = 0;
                }
            }
        ).catch(function (e) {
            console.log(e);
        });
        this.requestUpdate();
    }

    _onFocus(e) {
        e.preventDefault();
        this.hasFocus = true;
    }

    _performBlur() {
        if (!this.searchTypeSelected) {
            this._resetSearch();
            this.hasFocus = false;
        }
    }

    _onBlur(e) {
        e.preventDefault();
        this.onBlurTimeout = setTimeout(this._performBlur.bind(this), 250);
    }

    _clickSuggestion(e) {
        e.preventDefault();
        this.searchTagSelectionIndex = Number(e.target.dataset.selectionIndex);
        this._confirmSuggestion();
        this.requestUpdate();
    }

    _confirmSuggestion() {
        this.dispatchEvent(new CustomEvent('tagadded', {
            bubbles: true,
            composed: true,
            detail: {
                tag: this.searchTypeSuggestionTagArray[this.searchTagSelectionIndex]
            }
        }));
        this._resetSearch();
    }

    _resetSearch() {
        this.searchTerm = '';
        this.searchType = 0;
        this.searchTypeSelected = false;
        this.backspaceBufferTriggered = false;
        this.searchTypeSuggestionTagArray = [];
        this.searchTagSelectionIndex = -1;
        this.searchTimeout = -1;

        let inputElement = this.shadowRoot.querySelector('#search-input');
        inputElement.value = '';
        this.requestUpdate();
    }

    getSelectedSearchTypeTemplate() {
        if (this.searchTypeSelected) {
            if (this.searchType == 1) {
                return html`
                    <div class="search-type-selected search-type-selected--universe">Universe</div>
                `;
            }
            if (this.searchType == 2) {
                return html`
                    <div class="search-type-selected search-type-selected--character">Character</div>
                `;
            }
            if (this.searchType == 3) {
                return html`
                    <div class="search-type-selected search-type-selected--software">Software</div>
                `;
            }
            if (this.searchType == 4) {
                return html`
                    <div class="search-type-selected search-type-selected--misc">Misc</div>
                `;
            }
        }
        return html``;
    }

    _clickSearchTypeSuggestion(e) {
        e.preventDefault();
        if (this.onBlurTimeout > 0) {
            clearTimeout(this.onBlurTimeout);
            let inputElement = this.shadowRoot.querySelector('#search-input');
            inputElement.focus();
        }
        this.searchType = Number(e.target.dataset.id);
        this.searchTypeSelected = true;
        this._fetchTagList();
    }

    searchTypeSuggestionTagTemplate() {
        if (this.searchTypeSuggestionTagArray.length > 0) {
            let tagList = [];
            for (var i = 0; i < this.searchTypeSuggestionTagArray.length; i++) {
                var tag = this.searchTypeSuggestionTagArray[i];
                tagList.push(html`<div class="search-type-suggestion ${this.searchTagSelectionIndex == i ? 'search-type-suggestion--selected' : ''}" @click="${this._clickSuggestion}" data-selection-index="${i}">${tag.name}</div>`);
            }
            return tagList;
        }

        return html`<div class="search-type-suggestion>No results</div>`;
    }

    render() {
        return html`
            <div class="search-container ${this.hasFocus ? 'search-container--selected' : ''}">
                ${this.getSelectedSearchTypeTemplate()}
                <input type="text" id="search-input" name="search_text" placeholder="Search text..." value="${this.searchTerm}" autocomplete="off" @focus="${this._onFocus}" @blur="${this._onBlur}" @keyup="${this._inputBuffer}">
            </div>
            <div class="search-type-suggestions ${this.hasFocus ? 'search-type-suggestions--focused' : ''} ${this.searchTypeSelected ? 'search-type-suggestions--selected': ''}">
                <div class="search-type-suggestion ${this.searchType == 0 ? 'search-type-suggestion--selected' : ''}" data-id="0"><span class="search-type-suggestion-text">Text Search:</span>${this.searchTerm ? this.searchTerm : html`<span class="search-type-suggestion-muted"><pre>🠕</pre> and <pre>🠗</pre> select a query type</span>`}</div>
                <div class="search-type-suggestion ${this.searchType == 1 ? 'search-type-suggestion--selected' : ''}" data-id="1" @click="${this._clickSearchTypeSuggestion}"><span class="search-type-suggestion-text">Universe:</span><span class="search-type-suggestion-muted">the IP/Franchise the project relates to...</span></div>
                <div class="search-type-suggestion ${this.searchType == 2 ? 'search-type-suggestion--selected' : ''}" data-id="2" @click="${this._clickSearchTypeSuggestion}"><span class="search-type-suggestion-text">Character:</span><span class="search-type-suggestion-muted">a specific character model...</span></div>
                <div class="search-type-suggestion ${this.searchType == 3 ? 'search-type-suggestion--selected' : ''}" data-id="3" @click="${this._clickSearchTypeSuggestion}"><span class="search-type-suggestion-text">Software:</span><span class="search-type-suggestion-muted">the software the upload is compatible with...</span></div>
                <div class="search-type-suggestion ${this.searchType == 4 ? 'search-type-suggestion--selected' : ''}" data-id="4" @click="${this._clickSearchTypeSuggestion}"><span class="search-type-suggestion-text">Misc:</span><span class="search-type-suggestion-muted">other attributes of the upload...</span></div>
            </div>
            <div class="search-type-suggestions search-type-suggestions--focused">
                ${this.searchTypeSuggestionTagTemplate()}
            </div>
        `;
    }
}

window.customElements.define('search-input-element', SearchInputElement);